import { useResultsStore } from '../store/results.store';
import type { TPossibleNull } from '~/types/Shared.types';

import { useCurrencyStore } from '~/features/payment/store/currency.store';
import { useSumStore } from '~/features/payment/store/sum.store';
import { useTransactionStore } from '~/features/payment/store/transaction.store';
import { useMethodsStore } from '~/features/payment/store/methods.store';
import { PaymentEvents } from '~/repository/amplitude/events/payment';
import type { IPaymentPaymentStartedEvent } from '~/repository/amplitude/types/payment.types';

export enum EAmplitudeEvents {
  PAYMENT_COMPLETED = 'PAYMENT_COMPLETED',
  PAYMENT_FINISHED = 'PAYMENT_FINISHED',
  PAYMENT_STARTED = 'PAYMENT_STARTED',
}

enum EAmplitudeTransactionCurrencies {
  RUB = 'RUB',
  USD = 'USD',
}

enum EAmplitudeTransactionTypes {
  BALANCE = 'Balance Add',
  OFFER = 'Offer',
}

interface IAmplitudeTransaction {
  paymentCurrency: EAmplitudeTransactionCurrencies;
  paymentId: string;
  paymentMethod: string;
  paymentSum: number;
  paymentType: EAmplitudeTransactionTypes;
  userCurrency: string;
}

export function useAmplitudeTransaction(defaultEvent?: EAmplitudeEvents) {
  const currencyStore = useCurrencyStore();
  const { currency } = storeToRefs(currencyStore);

  const sumStore = useSumStore();
  const { sum } = storeToRefs(sumStore);

  const transactionStore = useTransactionStore();
  const { orderId } = storeToRefs(transactionStore);

  const methodsStore = useMethodsStore();
  const { selectedMethod } = storeToRefs(methodsStore);

  const resultsStore = useResultsStore();
  const { result } = storeToRefs(resultsStore);

  const event = ref<TPossibleNull<EAmplitudeEvents>>(defaultEvent ?? null);

  const transactionInfo = usePersistedRef<TPossibleNull<IAmplitudeTransaction>>(
    'transaction-info',
    null,
    EStorageTypes.SESSION,
  );

  const currentTransaction = computed<IAmplitudeTransaction>(() => {
    return {
      paymentCurrency: EAmplitudeTransactionCurrencies.RUB, // в данный момент для TD и TG - RUB, для GS - USD
      paymentId: orderId.value ?? '',
      paymentMethod: selectedMethod.value?.name ?? '',
      paymentSum: sum.value,
      paymentType: EAmplitudeTransactionTypes.BALANCE, // Offer будет, когда будет перенесен функционал покупки оффера
      userCurrency: currency.value?.code ?? '',
    };
  });

  const validTransaction = computed(() => {
    const { paymentId, paymentSum, paymentMethod, userCurrency } = currentTransaction.value;
    return paymentId && paymentSum && paymentMethod && userCurrency;
  });

  const setEvent = (newEvent: EAmplitudeEvents) => {
    event.value = newEvent;
  };

  const send = () => {
    // Если платеж только начинается, записываем в сессию
    if (validTransaction.value && event.value === EAmplitudeEvents.PAYMENT_STARTED) {
      transactionInfo.value = currentTransaction.value;
    }
    if (!transactionInfo.value) return;

    const data: IPaymentPaymentStartedEvent = {
      'Payment Currency': transactionInfo.value.paymentCurrency,
      'Payment Id': transactionInfo.value.paymentId,
      'Payment Method': transactionInfo.value.paymentMethod,
      'Payment Sum': transactionInfo.value.paymentSum,
      'Payment Type': transactionInfo.value.paymentType,
      'User Currency': transactionInfo.value.userCurrency,
    };

    if (event.value === EAmplitudeEvents.PAYMENT_STARTED) {
      PaymentEvents.paymentStarted(data);
      return;
    }

    if (event.value === EAmplitudeEvents.PAYMENT_FINISHED) {
      PaymentEvents.paymentFinished(data);
      return;
    }

    if (event.value === EAmplitudeEvents.PAYMENT_COMPLETED && result.value?.status === 'success') {
      PaymentEvents.paymentCompleted({ ...data, $revenue: result.value.sum });
    }
  };

  return {
    send,
    setEvent,
  };
}
